function Step1() {
  var self = this;

  this.init = function () {

    /* Show form on load */
    if ($('#step1').length) {
      $('#step1').removeClass("d-none");
    }

    this.validateApplication();
    this.bindEvents();

    /* Smoother data entry for the date of birth */
    $('.dob').on("input", function() {
      if($(this).val().length==$(this).attr("maxlength")){
        $(this).next().trigger("focus");
        $(this).next().trigger("select");
      }
    });

    // Make sure the address finder call is only applied on pages that our element exists on.
    if ($('#address_line1').length) {
      var widget;
      var initAddressFinder;
      widget, initAddressFinder = function() {
        widget = new AddressFinder.Widget(
          document.getElementById('address_line1'),
          'MR7WJD43FX8TACLBUNVH',
          'AU', {
            "address_params": {
              "post_box": "0"
            }
          }
        );

        /* Preselect the state option tag and default settings for the vic resident toggle*/
        if ($("#state").data('value')) {
          $("#state").val($("#state").data('value'));
        }
      
        if (($("#country").data('value')) || ($("#state").val() !== 'VIC')) {
          $("#vicres_toggle_other").prop("checked", true);
          if ($("#country").data('value')) {
            widget.disable();
            $("#country").val($("#country").data('value'));
            $("#international_block").show();
            $("#state").hide();
            $("#only_mandatory_for_aus").hide();
          } else {
            $("#country").val('Australia');
            $("#state_freetext").hide();
          }
        } else {
          $("#vicres_toggle_vic").prop("checked", true);
          $("#international_block").hide();
          $("#state_freetext").hide();
        } 

        widget.on('address:select', function(fullAddress, metaData) {
          document.getElementById('address_line1').value = metaData.address_line_1;
          if (typeof metaData.address_line_2 !== 'undefined') {
            document.getElementById('address_line2').value = metaData.address_line_2;
          }
          document.getElementById('suburb').value = metaData.locality_name;
          document.getElementById('state').value = metaData.state_territory;
          document.getElementById('postcode').value = metaData.postcode;
          $("#state").trigger('change');
        });

        $("#vicres_toggle_other").on('click',function() {
          if ($("#country").val() !== 'Australia') {
            widget.disable();
          } else {
            widget.enable();
          }
        });

        $("#vicres_toggle_vic").on('click',function() {
          widget.enable();
        });
        
      };

      this.downloadAddressFinder(initAddressFinder);
    }

    /* Email verification service */
    if ($('#email').length) {
      var conf = {
        addressSelector: "#email",
        licenceKey: "MR7WJD43FX8TACLBUNVH",

        rules: {
          disposable: {
            rule: "warn"
          },
          role: {
            rule: "warn",
            message:
              "We accept group email addresses, but prefer you enter your own email address"
          },
          public: {
            rule: "allow"
          },
          unverified: {
            rule: "warn"
          }
        }
      };
      this.downloadEmailVerification(conf);
    }

    /* Hide the donation amount free text box on page load & show if we are coming back from step 2 */
    if ($('form input[type=radio][name=donation_preset_amount]').length) {
      if ($('form input[type=radio][name=donation_preset_amount]').is(":checked")) {
        $('#toggle_donations').prop('checked', true);
        self.toggle('#donations_block', $('#toggle_donations'));
        if (parseInt($('form input[type=radio][name=donation_preset_amount]:checked').val()) == 5) {
          $("#other_amount_div").show();
        } else {
          $("#other_amount_div").hide();
        }
      } else {
        self.toggle('#donations_block', $('#toggle_donations'));
      }

      if (parseInt($('form input[type=radio][name=donation_type]:checked').val()) == 2) {
        $("#donation_monetary_amounts").hide();
        $("#donation_bookplate").show();
      } else {
        $("#donation_monetary_amounts").show();
        $("#donation_bookplate").hide();
      }
    }
    
  }

  this.downloadAddressFinder = function (initAddressFinder) {
      var script = document.createElement('script');
      script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
      script.async = true;
      script.onload = initAddressFinder;
      document.body.appendChild(script);
  };

  this.downloadEmailVerification = function (conf) {
    var e=document.createElement("script");
    e.src="https://api.addressfinder.io/assets/email/v1/widget.js",e.async=!0,
      e.onload=function(){
        new AddressFinder.Email.Widget(conf.addressSelector, conf.licenceKey, conf)}
      ,document.body.appendChild(e);
  };

  this.validateApplication = function () {

    jQuery.validator.addMethod('check_mobile', function(value, element,params) {

      if (value) {
        value = value.replace(/\s/g, '');
        return /^(\+614|614|04)\d{8}$/.test(value);
      } else { return true; }

    }, '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span>  Please provide an Australian mobile number.');

    jQuery.validator.addMethod('email_filled', function(value, element,params) {

      if (value) {
        if ($("#email").val()) { return true; } else { return false; }
      } else { return true; }

    }, ' You must enter your email address to subscribe to our enewsletter.');

    // Function with date logic to return whether this is actually a valid date
    jQuery.validator.addMethod("check_date", function(i,element) {
      
      let day = Number($("#dob_day").val());
      let month = Number($("#dob_month").val()) - 1; //bloody 0-indexed month
      let year = Number($("#dob_year").val());
      let validDate;
  
      let parsedDate = new Date(year, month, day);
  
      if (parsedDate.getFullYear() == year && parsedDate.getMonth() == month && parsedDate.getDate() == day) {
        validDate = 1;
      }

      var todaysDate = new Date();

      var dateInPast = new Date();
      dateInPast.setYear(dateInPast.getFullYear() - 120);

      var tooYoung = new Date();
      tooYoung.setYear(tooYoung.getFullYear() - 12);

      var consentStart = new Date();
      consentStart.setYear(consentStart.getFullYear() - 12);

      var consentEnd = new Date();
      consentEnd.setYear(consentEnd.getFullYear() - 16);

      if (!validDate) {
        jQuery.validator.messages.check_date = '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> The date entered is invalid';
        return false;
      } else if (todaysDate < parsedDate) {
        jQuery.validator.messages.check_date = '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Your birthdate cannot be in the future';
        return false;
      } else if (parsedDate < dateInPast) {
        jQuery.validator.messages.check_date = '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> The date is too far in the past';
        return false;
      /*} else if (parsedDate > tooYoung) {
        jQuery.validator.messages.check_date = "You are too young to use this form (Review copy)";
        return false;*/
      /*} else if ((parsedDate < consentStart) && (parsedDate > consentEnd)) {
        jQuery.validator.messages.check_date = "Consent is required (Review copy)";
        return false;*/
      } else {
        return true;
      }
  
    }, jQuery.validator.messages.check_date);

    jQuery.validator.addMethod("noHTMLtags", function(value, element){
      if(/<\/?[^>]+(>|$)/g.test(value)){
          return false;
      } else {
          return true;
      }
    }, '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> HTML tags are not allowed.');

    jQuery.validator.addMethod("validDomain", function(value, element) {

      var bannedDomain = 'slv.vic.gov.au';
      const email = value.split("@");
  
      if (bannedDomain === email[1].toLowerCase()) { return false } else { return true }

    }, '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> You cannot use an SLV email to become a member. Please provide a personal email address.');


    $("#step1").validate({

      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if ((element.attr("type") == "checkbox") || (element.hasClass("dob")) || (element.hasClass("bookplate_radio"))) {
          error.prependTo(element.closest("div.form-group"));
        } else {
          error.insertBefore(element);
        }
      },

      invalidHandler: function(form, validator) {
        var errors = validator.numberOfInvalids();
        if (errors) {                    
          validator.errorList[0].element.focus();
          validator.errorList[0].element.select;
        }
      }, 

      rules: {
        first_name: {
          noHTMLtags: true
        },
        last_name: {
          noHTMLtags: true
        },
        email: {
          validDomain: true
        },
        staff_number: {
          noHTMLtags: true
        },
        phone: {
          noHTMLtags: true,
          check_mobile: true
        },
        donation_other_amount: {
          required: true
        },
        enewsletter: {
          email_filled: true
        },
        dob_day: {
          check_date: true
        },
        address_line1: {
          noHTMLtags: true
        },
        address_line2: {
          noHTMLtags: true
        },
        suburb: {
          noHTMLtags: true
        },
        state: {
          noHTMLtags: true
        },
        postcode: {
          noHTMLtags: true
        },
        country: {
          noHTMLtags: true
        }
      },

      messages: {
        donation_other_amount: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a donation amount, like 25',
          min: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Donation amount must be 1 or more'
        },
        first_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your given name'
        },
        last_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your family name'
        },
        dob: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your date of birth',
          max: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Your date of birth must not be greater than today\'s date (e.g. dd/mm/yyyy)',
          min: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Please enter a date of birth which is no less than 120 years ago (e.g. dd/mm/yyyy)'
        },
        dob_day: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your day of birth',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter only numbers for your day of birth'
        },
        dob_month: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your month of birth',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter only numbers for your month of birth'
        },
        dob_year: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your year of birth',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter only numbers for your year of birth'
        },
        email: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your email address',
          email: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter an email address in the correct format, like name@example.com'
        },
        staff_number: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your staff or volunteer number'
        },
        phone: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your phone number',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Phone must be 8 characters or more',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Phone number must only include digits 0 to 9, hyphens, plus, spaces and brackets'
        },
        address_line1: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your address line 1'
        },
        suburb: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your suburb/town'
        },
        state: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your state'
        },
        postcode: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your postcode'
        },
        country: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your country'
        },
        donation_preset_amount: {
          required: function() {
            if (parseInt($('form input[type=radio][name=donation_type]:checked').val()) == 2) {
              return '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Select a bookplate';
            } else {
              return '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Select a donation amount';
            }
          }
        },
        bookplate_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the bookplate name'
        }
      },

      submitHandler: function(form) {

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#step1').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            form.submit();
          });
        });

      }

    });

  }

  this.toggle = function(className, obj) {
    var $input = $(obj);
    if ($input.prop('checked')) $(className).show();
    else $(className).hide();
  }

  this.bindEvents = function () {

    /* Show hide the donation block */
    $("#toggle_donations").on('click',function() {
      self.toggle('#donations_block', this);
      if (parseInt($('form input[type=radio][name=donation_preset_amount]:checked').val()) == 5) {
        $("#other_amount_div").show();
      } else {
        $("#other_amount_div").hide();
      }
    });

    /* Show hide the monetary & bookplate blocks */
    $("#donation_type1").on('click',function() {
      $("#donation_monetary_amounts").show();
      $("#donation_bookplate").hide();
    });

    $("#donation_type2").on('click',function() {
      $("#donation_monetary_amounts").hide();
      $("#donation_bookplate").show();
    });

    /* Show hide the donation amount free text box */
    $("#donation_amount_5").on('click',function() {
      $("#other_amount_div").show();
    });

    $(".hide_other_amount_div").on('click',function() {
      $("#other_amount_div").hide();
    });

    $("#back-to-landing").on('click',function() {
      window.location = "https://www.slv.vic.gov.au/";
    });

    /* Show hide the country & state blocks */
    $("#vicres_toggle_vic").on('click',function() {
      $("#state").show();
      $("#state").val('VIC');
      $("#only_mandatory_for_aus").show();
      $("#state_freetext").hide();
      $("#international_block").hide();
    });

    $("#vicres_toggle_other").on('click',function() {
      $("#international_block").show();
      if ($("#country").val() === 'Australia') {
        $("#state").show();
        /* If they have selected that their address is not in Vic the state drop down should not default to Vic */
        if ($("#state").val() === 'VIC') {
          $("#state").val('');
        }
        $("#state_freetext").hide();
      } else {
        $("#state_freetext").show();
        $("#only_mandatory_for_aus").hide();
        $("#state").hide();
      }
    });

    $("#country").on('change',function() {
      if ($(this).val() === 'Australia') {
        $("#state").show();
        /* If they have selected that their address is not in Vic the state drop down should not default to Vic */
        if ($("#state").val() === 'VIC') {
          $("#state").val('');
        }
        $("#only_mandatory_for_aus").show();
        $("#state_freetext").hide();
        $("#vicres_toggle_vic").trigger('click');
      } else {
        $("#state_freetext").show();
        $("#only_mandatory_for_aus").hide();
        $("#state").hide();
        $("#vicres_toggle_other").trigger('click');
      }
    });

    $("#state").on('change',function() {
      if ($(this).val() === 'VIC') {
        $("#vicres_toggle_vic").prop("checked", true);
        $("#vicres_toggle_vic").trigger('click');
      } else {
        $("#country").val('Australia');
        $("#vicres_toggle_other").prop("checked", true);
        $("#vicres_toggle_other").trigger('click');
      }
    });

  }

}

module.exports = Step1;
